import './difficulty.css'

const Difficlutybuttons = ({ onClick, selectedDifficulty }) => {
    const difficultyData = {
      Easy: 'Easy',
      Average: 'Average',
      Hard: 'Hard',
    };
  
    return (
      <div className="difficulty-box">
        {Object.entries(difficultyData).map(([difficulty, label], index) => (
          <button 
            key={index} 
            onClick={() => onClick(difficulty)}
            className={selectedDifficulty === difficulty ? 'active' : ''}
          >
            {label}
          </button>
        ))}
      </div>
    );
};


export default Difficlutybuttons;