import React, { useState } from 'react';
import '../aiPrompt/aiprompt.css'; // Import your CSS file for styling
import './subject.css'
import {subjectsData} from '../../data/lessonlist.js'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Tabs,
    Tab,
    Button,
    AddIcon
  } from '@mui/material';



  

const SubjectsLibrary = ({ onSelect, open, onClose }) => {
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Number and Place value');
    const [customLesson, setCustomLesson] = useState('');
    const [customLessons, setCustomLessons] = useState([]);

    const handleAddCustomLesson = () => {
      if (customLesson.trim()) {
        setCustomLessons([...customLessons, customLesson.trim()]);
        setSelectedSubjects([...selectedSubjects, customLesson.trim()]);
        setCustomLesson('');
      }
    };

    const handleRemoveLesson = (lessonToRemove) => {
      setCustomLessons(customLessons.filter((lesson) => lesson !== lessonToRemove));
    };


    const handleToggle = (subject) => () => {
      const currentIndex = selectedSubjects.indexOf(subject);
      const newSelected = [...selectedSubjects];
  
      if (currentIndex === -1) {
        newSelected.push(subject);
      } else {
        newSelected.splice(currentIndex, 1);
      }
  
      setSelectedSubjects(newSelected);
    };
  
    const handleDone = () => {
      const selectedSubjectsWithBr = selectedSubjects.map(subject => `- ${subject}<br />`);
      onSelect(selectedSubjectsWithBr.join(''));
      onClose();
    };

    const handleTabChange = (event, newValue) => {
        setSelectedCategory(newValue);
      };


  
      return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle> LESSON LIBRARY 📚</DialogTitle>
      
          <DialogContent>
      
            <Tabs 
              value={selectedCategory} 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab key="custom-lessons" label="Custom Lessons ✨" value="custom-lessons" />
              {Object.keys(subjectsData).map((category) => (
                <Tab key={category} label={category} value={category} />
              ))}
              
            </Tabs>
      
            {selectedCategory === 'custom-lessons' ? (
              <>
              <div className='custom-lesson-div'style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <input
                  className='custom-lesson-input'
                  type="text"
                  value={customLesson}
                  onChange={(e) => setCustomLesson(e.target.value)}
                  placeholder="Type your lesson here"
                  style={{ flexGrow: 1, padding: '12px', margin: '8px 0' }} // input grows to fill available space
                />
                <Button 
                className="add-custom-lesson-btn"
                onClick={handleAddCustomLesson} 
                variant="contained"
                
                >
                  Add Lesson
                </Button>
                </div>
                <List>
                  {customLessons.map((lesson) => (
                    <ListItem key={lesson} button onClick={handleToggle(lesson)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedSubjects.indexOf(lesson) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={lesson} />
                      <Button
        onClick={() => handleRemoveLesson(lesson)}
        style={{ marginLeft: 'auto', color: 'red' }} // Adjust color and margin as needed
      >
        X
      </Button>
                    </ListItem>
                  ))}
                </List>
               
              </>
            ) : (
              <List>
                {subjectsData[selectedCategory].map((subject) => (
                  <ListItem key={subject} button onClick={handleToggle(subject)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedSubjects.indexOf(subject) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={subject} />
                  </ListItem>
                ))}
              </List>
            )}
      
            <Button onClick={handleDone} variant="contained" color="primary">
              Done
            </Button>
      
          </DialogContent>
        </Dialog>
      );
  };

  export default SubjectsLibrary;