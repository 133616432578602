import { useEffect, useState } from "react";
import './Columnquestions.css';
import Joyride from 'react-joyride';
import {Template2Steps} from '../../data/joyrideSteps.js'

const Columnquestions = ({ generateContentAndPdf }) => {
  const [prompt, setPrompt] = useState(null);
  const [selectedOperation, setOperation] = useState(null);
  const [firstNumberDigits, setFirstNumberDigits] = useState(4); // Default to 5 digits for the first number
  const [secondNumberDigits, setSecondNumberDigits] = useState(3);
  const [questionCount, setquestionCount] = useState(20);
  const [run, setRun] = useState(false);

  const [errors, setErrors] = useState({
    operationError: '',
    firstNumError: '',
    secondNumError: '',
  });

 
    //New Users Pop Up
    useEffect(() => {
      // Check if the user has seen the guide before
      const hasVisitedBefore = localStorage.getItem('hasVisited1');
      
      if (!hasVisitedBefore) {
        // If not, set run to true to show the guide
        setRun(true);
        // Save a flag in localStorage so the guide doesn't show again
        localStorage.setItem('hasVisited1', 'true');
      }
    }, []);


  //Replace "20" with ${questionCount} to make the question count a dynamic field
  const statusText = `Generate a list of 20 ${selectedOperation} questions where the first number is ${firstNumberDigits} digits and the second number is ${secondNumberDigits} digits.
  do not use commas to show placeholders
 Please also provide the answers separately after the questions under a title that says answers
  `;


  useEffect(() => {
    setPrompt(statusText);
  }, [selectedOperation, firstNumberDigits, secondNumberDigits, questionCount]);


  const submitHandler = (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({
      operationError: '',
      firstNumError: '',
      secondNumError: '',
    });

    let hasError = false;

    // Validation
    if (!selectedOperation) {
      setErrors(prevErrors => ({ ...prevErrors, operationError: 'Please select an operation.👆🏻' }));
      hasError = true;
    }
    if (firstNumberDigits < 1 || firstNumberDigits > 6) {
      setErrors(prevErrors => ({ ...prevErrors, firstNumError: 'Please enter a valid number of digits for the 1st number.' }));
      hasError = true;
    }
    if (secondNumberDigits < 1 || secondNumberDigits > 6) {

      setErrors(prevErrors => ({ ...prevErrors, secondNumError: 'Please enter a valid number of digits for the 2nd number.' }));
      hasError = true;
    }

    // If no errors, generate the content and PDF
    if (!hasError) {
      generateContentAndPdf(prompt);
    }
  };


  const changeone = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 6)) {
      setFirstNumberDigits(value);

      // Clear the error if the field is filled
  if (value.trim() !== '') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      countError: ''
    }));
  }
    }
  };


  const changetwo = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 6)) {
      setSecondNumberDigits(value);

      // Clear the error if the field is filled
  if (value.trim() !== '') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      countError: ''
    }));
  }
    }
  };


return (
    <div className="column-questions">
       <Joyride
        steps={Template2Steps}
        run={run}
        continuous
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
      {/* Operation Buttons */}
      <div className="operation-buttons-div">
        <button 
          className={`operation-button ${selectedOperation === 'addition' ? 'selected' : ''}`}
          onClick={() => { setOperation('addition');}}>
          Addition
        </button>
        <button 
          className={`operation-button ${selectedOperation === 'subtraction' ? 'selected' : ''}`}
          onClick={() => { setOperation('subtraction');; }}>
          Subtraction
        </button>
        <button 
          className={`operation-button ${selectedOperation === 'multiplication' ? 'selected' : ''}`}
          onClick={() => { setOperation('multiplication'); }}>
          Multiplication
        </button>
      </div>
      {errors.operationError && <p className="error-msg">{errors.operationError}</p>}

  <div className="inputs-container">
  {/* Question Count Input */}
  {/* <div className="input-group">
    <label htmlFor="question-count">Question Count:</label>
    <input 
      id="question-count"
      placeholder="Question Count"
      type="number"
      value={questionCount}
      onChange={(e) => setquestionCount(e.target.value)} 
      min="4" 
      step="4" // Restrict to multiples of 4
    />
  </div> */}

  {/* First Number Digits Input */}
  <div className="input-group">
    <label htmlFor="first-number">1st Number Digit Count:</label>
    <input 
    className="input-column-temp"
      id="first-number"
      type="number"
      value={firstNumberDigits}
      onChange={changeone} 
      min="1"
      max="6"
    />
 {errors.firstNumError && <p className="error-msg">{errors.firstNumError}</p>} 
  </div>

  {/* Second Number Digits Input */}
  <div className="input-group">
    <label htmlFor="second-number">2nd Number Digit Count:</label>
    <input 
        className="input-column-temp"
      id="second-number"
      type="number"
      value={secondNumberDigits}
      onChange={changetwo} 
      min="0"
      max="6"
    />
    {errors.secondNumError && <p className="error-msg">{errors.secondNumError}</p>} 
  </div>
</div>




      {/* Generate Button */}
      <div className="generate-button-div">
        <button 
          className="generate-button"
          onClick={submitHandler}
          disabled={firstNumberDigits < 1 || secondNumberDigits < 1}> {/* Disable if inputs are invalid */}
          Generate &#10024;
        </button>
      </div>
    </div>
  );
}

export default Columnquestions;