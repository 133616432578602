import React from 'react'

import Layout from './components/Layout/Layout';
import { MathJaxContext } from 'better-react-mathjax';
import {BrowserRouter, Routes, Route} from 'react-router-dom'


const mathJaxConfig = {
  loader: { load: ['input/asciimath', 'output/chtml'] }
};


function App() {
  return (
    <div>
      <MathJaxContext version={3} options={mathJaxConfig}>

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}></Route>
      </Routes> 
    </BrowserRouter>

    </MathJaxContext>
    </div>
  );
}
 
export default App;