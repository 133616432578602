//Joyride steps for the first-time Users


export  const TemplateCarouselStep = [
    {
      target: '.next-button',
      content: (
        <div>
          <strong>Step 1:</strong> Pick a template.
        </div>
      ),
    }] 


export const Template1Steps = [
    {
      target: '.basic_details_name',
      content: (
        <div>
          <strong>Step 2:</strong> Give your worksheet a unique and descriptive name.
        </div>
      ),
    },
    {
      target: '.basic_details_count',
      content: (
        <div>
          <strong>Step 3:</strong> Specify how many questions you'd like in your worksheet. <br />
          <em>Max: 20</em> (Don't worry, this limit will be increased soon! 😅)
        </div>
      ),
    },
    {
      target: '.yeargroup-buttons-div',
      content: (
        <div>
          <strong>Step 4:</strong> Choose the year group for which the questions will be generated. <br />
          This helps us match the content to the correct skill level.
        </div>
      ),
    },
    {
      target: '.difficluty-buttons-div',
      content: (
        <div>
          <strong>Step 5:</strong> Set the difficulty level. <br />
          Whether your students are beginners or experts, we’ve got you covered.
        </div>
      ),
    },
    {
      target: '.subjects-library-div',
      content: (
        <div>
          <strong>Step 6:</strong> Browse our extensive library to choose the relevant topics. <br />
          Don’t see a topic you need? Let us know, and we’ll be happy to add it!
        </div>
      ),
    },
    {
      target: '.generate-button',
      content: (
        <div>
          <strong>Step 7:</strong> Click here to generate your custom worksheet and download it as a PDF! 🎉
        </div>
      ),
    },
  ];

export  const Template2Steps = [
    {
      target: '.operation-buttons-div',
      content: (
        <div>
          <strong>Step 2:</strong> Select the type of operation for the questions.
        </div>
      ),
    },
    {
      target: '#first-number',
      content: (
        <div>
          <strong>Step 3:</strong> Specify the number of digits for the first number in the column <br />
          <em>Maximum: 6 digits</em> (e.g., up to 4 digits for thousands)
        </div>
      ),
    },
    {
      target: '#second-number',
      content: (
        <div>
          <strong>Step 4:</strong> Specify the number of digits for the second number in the column <br />
          <em>Maximum: 6 digits</em> (e.g., up to 3 digits for hundreds)
        </div>
      ),
    }
    ,
    {
      target: '.generate-button',
      content: (
        <div>
          <strong>Step 5:</strong> Click here to generate your custom worksheet and download it as a PDF! 🎉
        </div>
      ),
    }
    ]




