//Lesson List

export const  subjectsData = {

    'GCSE Revision': [
    "Understanding Fractions",
    "Calculating with Fractions in Context",
    "Working with Fractions, Decimals and Percentages",
    "Working with Ratio",
    "Combining and Subdividing Ratios",
    "Expanding Double Brackets",
    "Using Pythagoras' Theorem",
    "Using Index Laws",
    "Factorising Quadratics (coefficient equal to 1)",
    "Trigonometry: Using SOHCAHTOA",
    "Working with Probabilities",
    "Forming and Solving Linear Equations",
    "Working with Arithmetic Sequences",
    "Working with Prime Factorisation",
    "Solving Area and Perimeter Problems",
    "Calculating the Volume of Prisms and Cylinders",
    "Calculating the Surface Area of Prisms and Cylinders",
    "Calculating Averages",
    "Calculating with Compound Measures",
    "Solving Linear Simultaneous Equations",
    "Finding the area, circumference and lengths of circles, arcs and sectors",
    "Calculating Compound Interest and Depreciation",
    "Working with Standard Form",
    "Calculating with Reverse Percentages",
    "Finding Probabilities Using Tree Diagrams",
    "Finding Angles in Polygons",
    "Calculating Average Speed and Density",
    "Factorising Quadratics (coefficient not equal to 1)"
  ],
    'Number and Place value': [
      'Counting (in multiples)',
      'Read, write, order and compare numbers',
      'Identify, represent and estimate, rounding',
      'Place value, Roman numerals'
  ],
    'Fractions, Decimals and Percentages': [
      'Equivalent fractions',
      'Recognise, find, write, name and count fractions',
      'Comparing and ordering fractions',
      'Add / subtract fractions',
      'Compare and order decimals',
      'Multiply / divide decimals',
      'Fractions / Decimals / Percentage equivalence',
      'Multiply / divide fractions',
      'Solve problems with fractions and decimals',
      'Solve problems with percentages'
  ]
  ,
    'Addition, Subtraction, Multiplication and Division': [
      'Add / subtract using written methods',
      'Estimate, use inverses and check',
      'Add / subtract mentally',
      'Add / subtract to solve problems',
      'Properties of number (multiples, factors, primes, squares and cubes)',
      'Multiply / divide mentally',
      'Multiply / divide using written methods',
      'Order of operations',
      'Solve problems (commutative, associative, distributive and all four operations)',
      'Negative numbers'
  ],
    'Ratio and Proportion': ['Using ratio language and symbol', 'Calculating ratio', 'Using ratio grids to solve problems', 'Using scale factors', 'Solving problems involving ratio and proportion', 'Comparing ratios and fractions'],
   
    'Measurement': [
      'Compare, describe and order measures',
      'Estimate, measure and read scales',
      'Money',
      'Telling time, ordering time, duration and units of time',
      'Convert between metric units',
      'Convert metric/imperial',
      'Perimeter, area',
      'Volume',
      'Solve problems (a, money, b, length, c, mass / weight, d, capacity / volume)'
  ],
   
  //   'Geometry - Properties of shapes': [
  //     'Angles - measuring and properties',
  //     'Describe properties and classify shapes',
  //     'Draw and make shapes and relate 2-D to 3-D shapes (including nets)',
  //     'Recognise and name common shapes',
  //     'Circles',
  //     'Describe position, direction and movement',
  //     'Coordinates'
  // ],
   
    'Algebra': [
      'Missing number problems expressed in algebra',
      'Simple formulae expressed in words',
      'Number sentences involving two unknowns',
      'Generate and describe linear number sequences',
      'Enumerate all possibilities of combinations of two variables'
  ]
  };