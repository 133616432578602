import './yeargroup.css'


const Yeargroupbuttons = ({ onClick, selectedYeargroup }) => {
    const yearData = {
        'Year 1' : 'Year 1',
        'Year 2' : 'Year 2',
        'Year 3' : 'Year 3',
        'Year 4' : 'Year 4',
        'Year 5' : 'Year 5',
        'Year 6' : 'Year 6',
        'Year 7' : 'Year 7',
        'Year 8' : 'Year 8',
        'Year 9' : 'Year 9',
        'GCSE' : 'GCSE',
    
    };


  
  
    return (
      <div className="yeargroup-box">
        {Object.entries(yearData).map(([yeargroup, label], index) => (
          <button 
            key={index} 
            onClick={() => onClick(yeargroup)}
            className={selectedYeargroup === yeargroup ? 'active' : ''}
            variant="outlined"
          >
            {label}
          </button>
        ))}
      </div>
    );
};


export default Yeargroupbuttons;