import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import logo from '../../assets/logo192.png';

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 20,
    fontSize: 20,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 0, // Remove margin between rows for a continuous gridline
  },
  cell: {
    flexGrow: 1,
    textAlign: 'right',
    width: '25%', // Adjust width as needed
    fontSize: 25,
    letterSpacing: 2,
    borderLeftWidth: 10,
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff',
    marginRight: 10,
  },
  emptyCell: {
    borderWidth: 1, // Add border on all sides for empty cells only
    borderColor: '#000000', // Keep it the same as other cells
    padding: 20, // Add padding inside empty cells for spacing
    borderLeftWidth: 10,
    borderLeftColor: '#ffffff',
    borderRightColor: '#ffffff'
  },
  firstRowSpacing: {
    marginTop: 20, // Adjust this value as needed for the spacing
  },
  // lastRow: {
  //   borderBottomWidth: 1, // Use the same border width as other rows
  //   borderBottomColor: '#000000',
  // },
  lastRowCell: {
    borderRightWidth: 1, // Ensure border on the right side
    borderColor: '#000000',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  studentInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
  },
  studentInfoBox: {
    borderWidth: 1,
    borderColor: '#000000',
    padding: 8,
    width: '45%',
  },
  image: {
    width: '300px',      // Set a fixed width
    height: 'auto',      // Maintain aspect ratio
    objectFit: 'contain',
    marginBottom: 40,
    padding: 8,
    alignSelf: 'flex-start'
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 10,
  },
  logo: {
    width: 20,
    height: 20,
  },
  answerText: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'left',
  },
  
});

// Define the PDF document component
const ColumnAdditionTemplate = ({ questions }) => {
console.log("questions the template recieves")
  console.log(questions)


  const questionItems = questions.filter((item) => item.question);
  const answerItems = questions.filter((item) => item.answer);

  const processQuestions = (questions) => {
    const processedQuestions = [];
    questions.forEach((questionObj) => {
      const content = questionObj.content;
      // Use a regex to extract two numbers from the content string
      const match = content.match(/(\d+)\s*([\+\-\×\*])\s*(\d+)/);
      
      if (match) {
        const firstNumber = match[1];
        const operator = match[2];  
        const secondNumber = match[3];
  
   
        const formattedSecondNumber = `${operator} ${secondNumber}`;

        processedQuestions.push(firstNumber, formattedSecondNumber);
      }
    });
    return processedQuestions;
  };


  const extractAnswers = (questions) => {
    return questions
      .filter((item) => item.type === 'text-answer')
      .map((item) => item.content); // Extracts the content of each answer
  };



  // Function to create rows from the questions array
  const createRows = (questions) => {
    const rows = [];
    for (let i = 0; i < questions.length; i += 8) {
      // Take up to 4 questions per row
      const firstrow = [
        questions[i] || '',
        questions[i + 2] || '',
        questions[i + 4] || '',
        questions[i + 6] || '',
      ];

      const secondrow = [
        questions[i + 1] || '',
        questions[i + 3] || '',
        questions[i + 5] || '',
        questions[i + 7] || '',
      ];

      rows.push(firstrow); // Add row for standalone numbers
      // Remove the line that adds the addition sign
      rows.push(secondrow); // Add row for addition numbers (unchanged)
      rows.push(['', '', '', '']); // Add empty row for answers
    }
    return rows.slice(0, 15); 
  };

  // Process the array of objects to get an array of numbers and operators
  const processedQuestions = processQuestions(questions);
  
  // Create rows from the processed questions array
  const rows = createRows(processedQuestions);

  const answers = extractAnswers(questions);
  console.log(answers) 

  return (
    <Document>
      {/* First Page with Questions */}
      <Page style={styles.page}>
        <View style={styles.studentInfoContainer}>
          <View style={styles.studentInfoBox}>
            <Text>Student Name:</Text>
          </View>
          <View style={styles.studentInfoBox}>
            <Text>Score:</Text>
          </View>
        </View>

        <View style={styles.table}>
          {rows.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={[
                styles.tableRow,
                rowIndex % 3 === 2 ? styles.lastRow : {}, // Style for the last row in a group
                rowIndex % 3 === 0 ? styles.firstRowSpacing : {}, // Add spacing before first row in each group
              ]}
            >
              {row.map((item, colIndex) => (
                <Text
                  key={colIndex}
                  style={[
                    styles.cell,
                    item === '' ? styles.emptyCell : {}, // Apply emptyCell style conditionally
                    rowIndex % 3 === 2 ? styles.lastRowCell : {}, // Style for last cell in a group
                  ]}
                >
                  {item}
                </Text>
              ))}
            </View>
          ))}
        </View>
        <View style={styles.footer}>
          <Text>Created via Worksheep</Text>
          <Image style={styles.logo} src={logo} />
        </View>
      </Page>

      <Page style={styles.page}>
  <Text style={styles.title}>Answers</Text>
  {answers.map((answer, index) => (
    <Text key={index} style={styles.answerText}>
      {answer}
    </Text>
  ))}
</Page>
    </Document>
  );
};

export default ColumnAdditionTemplate;