import React from 'react';
import './navbar.css';
import Logo from '../Navbar/sheep.svg';
import Tooltip from '@mui/material/Tooltip';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className='navbar_logo-title'>
        <h1 className='navbar_title'>Worksheep</h1>
        <img src={Logo} alt='Logo' className='navbar_logo' />
        {/* <div className='beta_container'>
          <span className='beta_label'>BETA</span>
        </div> */}
      </div>
      <ul className='navbar_links'>
        <li className='p4'>
  <a href='https://www.theworksheep.com/' target='_blank' rel='noopener noreferrer'>Home</a>
</li>
<li className='p4'>
  <a href='https://www.youtube.com/watch?v=co4koppeTUs&t=7s' target='_blank' rel='noopener noreferrer'>Guides</a>
</li>
<li className='p4'>
<a href="mailto:hello@theworksheep.com">Contact Us</a>
</li>
<li>
<Tooltip title='Share your ideas and feedback with us'>
  <a href='https://forms.gle/iTGhS8mc2CCreumB7' target='_blank' rel='noopener noreferrer'>
    <button className='allbutton'>Got Ideas?💡</button>
  </a>
  </Tooltip>
</li>
      </ul>
    </div>
  );
}

export default Navbar;